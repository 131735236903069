import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: [''],
    headline: ['text-headline-md sm:text-headline-lg'],
    caption: ['text-body-xs'],
  },
  variants: {
    size: {
      primary: {
        base: [
          'gap-x-3',
          'gap-y-3',

          '[&:has(article)]:max-w-tabloid',
          'container-col-sm-12:gap-x-3',

          'sm:gap-x-4.5',
          'sm:gap-y-6',

          'md:gap-x-6',
          'md:gap-y-8',
        ],
      },
    },
  },
});

export default Row;
