import { tw } from '@/utils/tw';
import BaseAdunitTheme from 'base/components/Adunit/theme';

const Adunit = tw.theme({
  extend: BaseAdunitTheme,
  slots: {
    base: `relative z-10 my-4 w-full bg-gray-100 text-center wings wings-gray-100 text-body-sm group-data-[opaque]/front:bg-white group-data-[opaque]/front:wings-white`,
  },
});

export default Adunit;
